'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _StackBlur = require('./lib/StackBlur.js');

var _StackBlur2 = _interopRequireDefault(_StackBlur);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

var animating = false;

var ReactBlur = function ReactBlur(props) {
  var _props$blurRadius = props.blurRadius,
      blurRadius = _props$blurRadius === undefined ? 0 : _props$blurRadius,
      _props$children = props.children,
      children = _props$children === undefined ? null : _props$children,
      _props$className = props.className,
      className = _props$className === undefined ? '' : _props$className,
      _props$enableStyles = props.enableStyles,
      enableStyles = _props$enableStyles === undefined ? false : _props$enableStyles,
      _props$onLoadFunction = props.onLoadFunction,
      onLoadFunction = _props$onLoadFunction === undefined ? function () {} : _props$onLoadFunction,
      _props$shouldResize = props.shouldResize,
      shouldResize = _props$shouldResize === undefined ? false : _props$shouldResize,
      _props$resizeInterval = props.resizeInterval,
      resizeInterval = _props$resizeInterval === undefined ? 64 : _props$resizeInterval,
      img = props.img,
      other = _objectWithoutProperties(props, ['blurRadius', 'children', 'className', 'enableStyles', 'onLoadFunction', 'shouldResize', 'resizeInterval', 'img']);

  var containerRef = (0, _react.useRef)();
  var canvasRef = (0, _react.useRef)();
  var imgRef = (0, _react.useRef)();
  var widthRef = (0, _react.useRef)();
  var heightRef = (0, _react.useRef)();

  (0, _react.useEffect)(function () {
    loadImage();

    if (shouldResize) {
      window.addEventListener('resize', resize);
    }

    return function () {
      window.removeEventListener('resize', resize);
    };
  }, []);

  (0, _react.useEffect)(function () {
    if (!imgRef.current) {
      loadImage(props);
    } else if (!isCurrentImgSrc(img)) {
      imgRef.current.src = img;
      setDimensions();
    } else {
      // if some other prop changed reblur
      if (animating) {
        return;
      }

      animating = true;

      window.requestAnimationFrame(function () {
        (0, _StackBlur2.default)(imgRef.current, canvasRef.current, blurRadius, widthRef.current, heightRef.current, function () {
          animating = false;
        });
      });
    }
  });

  var setDimensions = function setDimensions() {
    heightRef.current = containerRef.current.offsetHeight;
    widthRef.current = containerRef.current.offsetWidth;
    canvasRef.current.height = heightRef.current;
    canvasRef.current.width = widthRef.current;
  };

  var isCurrentImgSrc = function isCurrentImgSrc(newSrc) {
    // Handle relative paths
    if (imgRef.current) {
      var newImg = new Image();
      newImg.src = newSrc;
      // if absolute SRC is the same
      return newImg.src === imgRef.current.src;
    }

    return false;
  };

  var loadImage = function loadImage() {
    if (isCurrentImgSrc(imgRef.current)) {
      (0, _StackBlur2.default)(imgRef.current, canvasRef.current, blurRadius, widthRef.current, heightRef.current);
      return;
    }

    imgRef.current = new Image();
    imgRef.current.crossOrigin = 'Anonymous';

    imgRef.current.onload = function (event) {
      (0, _StackBlur2.default)(imgRef.current, canvasRef.current, blurRadius, widthRef.current, heightRef.current);
      onLoadFunction(event);
    };

    imgRef.current.onerror = function (event) {
      // Remove the onerror listener.
      // Preventing recursive calls caused by setting this.img.src to a falsey value
      imgRef.current.onerror = undefined;

      imgRef.current.src = '';
      onLoadFunction(event);
    };
    imgRef.current.src = img;

    setDimensions();
  };

  var last = void 0;
  var resize = function resize() {
    var now = new Date().getTime();
    var deferTimer = void 0;
    var threshhold = resizeInterval;

    if (last && now < last + threshhold) {
      clearTimeout(deferTimer);
      deferTimer = setTimeout(function () {
        last = now;
        doResize();
      }, threshhold);
    } else {
      last = now;
      doResize();
    }
  };

  var doResize = function doResize() {
    setDimensions();

    (0, _StackBlur2.default)(imgRef.current, canvasRef.current, blurRadius, widthRef.current, heightRef.current);
  };

  var classes = 'react-blur';
  if (className) {
    classes += ' ' + className;
  }

  var containerStyle = enableStyles ? {
    position: 'relative'
  } : {};
  var canvasStyle = enableStyles ? {
    position: 'absolute',
    top: 0,
    left: 0
  } : {};

  return _react2.default.createElement(
    'div',
    _extends({
      className: classes,
      ref: containerRef,
      style: containerStyle
    }, other),
    _react2.default.createElement('canvas', {
      className: 'react-blur-canvas',
      ref: canvasRef,
      style: canvasStyle
    }),
    children
  );
};

ReactBlur.propTypes = {
  blurRadius: _propTypes2.default.number,
  children: _propTypes2.default.node,
  className: _propTypes2.default.string,
  enableStyles: _propTypes2.default.bool,
  img: _propTypes2.default.string.isRequired,
  onLoadFunction: _propTypes2.default.func,
  shouldResize: _propTypes2.default.bool,
  resizeInterval: _propTypes2.default.number
};

exports.default = ReactBlur;
